/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { usePlacesWidget } from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import { TextField, makeStyles } from '@material-ui/core';

// import useOnclickOutside from 'react-cool-onclickoutside';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';

// import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles(() => ({
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#00000 !important'
  },
  CssLabel: {
    color: 'black'
  }
}));

export default function PlacesAutocomplete({
  setPostion,
  label,
  currentLocation,
  heightValue,
  setHeightValue,
  setPaths,
  Paths,
  index
}) {
  const classes = useStyles();

  const { ref: materialRef } = usePlacesWidget({
    onPlaceSelected: (place) => handleSelect(place),
    inputAutocompleteValue: 'country',
    options: {
      types: ['geocode'],
      componentRestrictions: { country: 'za' }
    }
  });

  const [inputValue, setInputValue] = useState('');
  const [heightInputWidth, setHeightInputWidth] = useState('w-16');

  useEffect(() => {
    if (currentLocation !== undefined) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
      Geocode.setRegion('za');

      Geocode.fromLatLng(currentLocation.lat, currentLocation.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);
          setInputValue(address);
        },
        (error) => {
          console.log('');
        }
      );
    }
  }, [currentLocation]);

  function handleSelect(place) {
    let position;

    if (place && place?.geometry) {
      position = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
      console.log('Found Address', position);
      updateMapMarkers(position);
    } else if (checkCoordinates(place)) {
      const coords = checkCoordinates(place);
      console.log(coords);
      position = new window.google.maps.LatLng(+coords[0], +coords[1]);
      updateMapMarkers(position);
    }
    setInputValue(place.formatted_address);
  }

  function checkCoordinates(coords) {
    if (!coords) return false;

    let splitVal = coords.name.split(',');
    if (!splitVal || splitVal.length < 2) splitVal = coords.split(' ');
    if (!splitVal || splitVal.length < 2 || !splitVal[0] || !splitVal[1]) return false;

    if (+splitVal[0] > -90 && +splitVal[0] < 90 && +splitVal[1] > -180 && +splitVal[1] < 180) return splitVal;

    return false;
  }

  function updateMapMarkers(position) {
    setPostion({ lat: position.lat(), lng: position.lng() });
  }

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  function handleOnclick() {
    setInputValue('');
    setPostion('');
    setPaths(Paths.splice(index, index));
  }

  const handleHeightChange = (e) => {
    if (e.target.value.toString().length > 2) {
      setHeightInputWidth('w-20');
    } else if (e.target.value.toString().length <= 2) {
      setHeightInputWidth('w-16');
    }

    if (e.target.value <= 100 && e.target.value >= 0) {
      setHeightValue(e.target.value);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      setHeightInputWidth('w-20');
    }
  }, []);

  return (
    <div className="App">
      <div className="flex items-baseline">
        <TextField
          onChange={handleChange}
          placeholder="Enter an address or XY (latitude, longitude)"
          value={inputValue}
          className="flex mt-2 w-1/2 max-w-xs bg-white rounded-md shadow-lg"
          label={label}
          variant="filled"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="start">
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    handleOnclick();
                  }}
                  style={{ cursor: 'pointer', paddingTop: '2px' }}
                >
                  <ClearIcon />
                </div>
              </InputAdornment>
            )
          }}
          color="primary"
          fullWidth
          // required for autocomplete to work
          inputRef={materialRef}
        />
        <div className="p-1" />
        <TextField
          onChange={handleHeightChange}
          className={`${heightInputWidth} flex bg-white rounded-md shadow-lg`}
          InputProps={{
            disableUnderline: true
          }}
          label="Height"
          variant="filled"
          type="number"
          color="primary"
          value={heightValue}
        />
      </div>
    </div>
  );
}
